
<template>
  <div class="maxpc">
    <div class="block site">
      <span class="demonstration">请选择要修改的站点：</span>
      <el-cascader placeholder="试试搜索：广东" :options="options" filterable @change="select"></el-cascader>
    </div>
    <el-tabs v-model="pcstyle" @tab-click="handleClick" class="pcstyle">
      <el-tab-pane label="浮动窗口" name="floatwindow" class="float-window">
        <div class="float-window-left">
          <p class="fwl-tle">1.聊天按钮设置</p>
          <div class="float-window-list">
            <label class="float-window-label">按钮样式</label>
            <div>
              <el-radio-group v-model="float_button_style" class="float-button-style" @change="fbsclick()">
                <el-radio-button label="btns1"></el-radio-button>
                <el-radio-button label="btns2"></el-radio-button>
                <el-radio-button label="btns3"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="float-window-list" style="display: none;">
            <label class="float-window-label">按钮位置</label>
            <div>
              <el-radio-group v-model="float_button_position" class="float-button-position" @change="fbpclick">
                <el-radio label="btnp1">像素</el-radio>
                <el-radio label="btnp2">百分比</el-radio>
              </el-radio-group>
              <div class="btnpctbox" v-show="float_button_position == 'btnp1'">
                <el-select v-model="btnpx" class="btnpxrbox" size="mini">
                  <el-option v-for="(item, index) in btnpxs" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-input-number v-model="btnpxrnum" controls-position="right" class="btnpxnum" :min="0" size="mini"></el-input-number>
                <el-select v-model="btnpy" class="btnpxrbox" size="mini">
                  <el-option v-for="(item, index) in btnpys" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-input-number v-model="btnpxbnum" controls-position="right" class="btnpxnum" :min="0" size="mini"></el-input-number>
              </div>
              <div class="btnpixbox" v-show="float_button_position == 'btnp2'">
                <label>距底</label>
                <el-input-number v-model="btnpxbnum" controls-position="right" class="btnpxnum" :min="0" size="mini" :step="5"></el-input-number>
                <label>距右</label>
                <el-input-number v-model="btnpxrnum" controls-position="right" class="btnpxnum" :min="0" size="mini" :step="5"></el-input-number>
              </div>
            </div>
          </div>
          <div class="float-window-list">
            <label class="float-window-label">主题与文本</label>
            <div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="themecolor" @active-change="themecolorchange"></el-color-picker>
                <span>主题颜色</span>
              </div>
              <div>
                <span>在线文本</span>
                <el-input v-model="onlinetxt" size="mini" class="linetxt onlinetxt" maxlength="15"></el-input>
                <p class="linetxttip">不超过 15 个汉字</p>
              </div>
              <div>
                <span>离线文本</span>
                <el-input v-model="offlinetxt" size="mini" class="linetxt offlinetxt" maxlength="15"></el-input>
                <p class="linetxttip">不超过 15 个汉字</p>
              </div>
            </div>
          </div>
          <p class="fwl-tle">2.聊天框设置</p>
          <div class="float-window-list" style="display: none;">
            <label class="float-window-label">窗口尺寸</label>
            <div>
              <el-radio-group v-model="float_window_size" class="float-window-size" @change="fwsclick">
                <el-radio label="360x460">360x460 像素</el-radio>
                <el-radio label="360x400">360x400 像素</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="float-window-list">
            <label class="float-window-label">颜色</label>
            <div class="windowsize">
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="kfbubble" @active-change="kfbubblechange"></el-color-picker>
                <span>客服气泡</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="kfmes" @active-change="kfmeschange"></el-color-picker>
                <span>客服消息</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="fkbubble" @active-change="fkbubblechange"></el-color-picker>
                <span>访客气泡</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="fkmes" @active-change="fkmeschange"></el-color-picker>
                <span>访客消息</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="sendbtn" @active-change="sendbtnchange"></el-color-picker>
                <span>发送按钮</span>
              </div>
            </div>
          </div>
          <div class="float-window-list" style="display: none;">
            <label class="float-window-label">输入区域功能</label>
            <div>
              <el-checkbox-group v-model="float_input_func" class="float-input-func" @change="fifclick">
                <el-checkbox label="fif1">发送表情</el-checkbox>
                <el-checkbox label="fif2">截图</el-checkbox>
                <el-checkbox label="fif3">发送文件及图片</el-checkbox>
                <el-checkbox label="fif4">保存聊天记录</el-checkbox>
                <el-checkbox label="fif5">评价</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <p class="fwl-tle">3.快速咨询菜单</p>
          <div class="float-window-list consultationmenu">
            <div class="consultationmenusub" v-for="(v, i) in consultation" :key="i">
              <el-input v-model="v.title" slot="prepend" placeholder="按钮名" maxlength="10"></el-input>
              <el-input v-model="v.content" slot="prepend" placeholder="回复内容" maxlength="200"></el-input>
            </div>
            <div class="consultationmenubtn">
              <el-button v-show="consultation.length > 0" @click="popconsultationmenu"><i class="el-icon-minus"></i></el-button>
              <el-button v-show="consultation.length < 9" @click="pushconsultationmenu"><i class="el-icon-plus"></i></el-button>
            </div>
          </div>
          <!-- 新建客户互动菜单弹窗 -->
          <el-dialog class="ziduan" :title="tctitle" :visible.sync="dialogVisible" width="380px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="菜单类型" prop="resource">
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio :label="1">快问快答</el-radio>
                  <el-radio :label="2">快速提问</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="ruleForm.resource == 1">
                <el-form-item label="菜单名称" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="回复内容" prop="desc">
                  <el-input type="textarea" v-model="ruleForm.desc" :autosize="{ minRows: 8, maxRows: 8 }"></el-input>
                </el-form-item>
              </div>
              <div v-else>
                <el-form-item label="菜单名称" prop="name" class="construction">
                  <el-input v-model="ruleForm.name" label-width="20px"></el-input>
                  <p class="caidan" @click="creation">+新建菜单</p>
                </el-form-item>

                <div class="demo-dynamic" v-if="erji">
                  <el-form-item prop="email" label="二级菜单">
                    <el-input v-model="ruleForm.email"></el-input>
                  </el-form-item>
                  <el-form-item v-for="(domain, index) in ruleForm.domains" label="二级菜单" :key="domain.key" :prop="'domains.' + index + '.value'" :rules="[{ required: true, message: '请输入菜单名称', trigger: 'blur' },
                  { min: 0, max: 12, message: '不超过 12 个字符', trigger: 'blur' }]">
                    <el-input v-model="domain.value"></el-input><el-button @click.prevent="removeDomain(domain)">删除</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="addDomain">新增菜单</el-button>
                  </el-form-item>
                </div>
              </div>


            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </span>
          </el-dialog>
          <p class="fwl-tle">4.客户互动菜单（最多10个）<el-button class="newaddmenu" size="mini" plain @click="hudong">+新增</el-button></p>
          <div class="menupreview usermenupreview">
            <span>提升访客对话率，提高访客转化</span>
            <span>预览具体外观<img src="../../img/interact_menu_prev_pc.png"></span>
          </div>
          <div class="menupreview usermenuautohide" style="display: none;">
            <span>自动消失</span>
            <el-checkbox v-model="usermenuautohide">访客点击互动菜单或说话后</el-checkbox>
          </div>
          <div class="float-window-list">
            <el-table :data="tableData" style="width: 100%" class="usermenucontent" :row-key="this.tableData.mainid">
              <el-table-column width="23">
                <template>
                  <!-- <svg-icon class="mobileitem" icon-class="mobileitem" /> 备份 -->
                </template>
              </el-table-column>
              <el-table-column label="菜单名称" prop="date">
              </el-table-column>
              <el-table-column label="菜单类型" prop="name">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" @click="usermenuedit(scope.$index, scope.row)">编辑</el-button>
                  <el-button size="mini" type="text" @click="usermenudel(scope.$index, tableData)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 新建岗位互动菜单弹窗 -->
          <el-dialog class="ziduan" :title="tctitle" :visible.sync="dialog1Visible" width="380px">
            <el-form :model="rule1Form" :rules="rules" ref="rule1Form" label-width="100px" class="demo-ruleForm">
              <el-form-item label="菜单类型" prop="resource">
                <el-radio-group v-model="rule1Form.resource">
                  <!-- <el-radio :label="1">快问快答</el-radio> -->
                  <el-radio :label="2">快速提问</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="rule1Form.resource == 1">
                <el-form-item label="菜单名称" prop="name">
                  <el-input v-model="rule1Form.name"></el-input>
                </el-form-item>
                <el-form-item label="回复内容" prop="desc">
                  <el-input type="textarea" v-model="rule1Form.desc" :autosize="{ minRows: 8, maxRows: 8 }"></el-input>
                </el-form-item>
              </div>
              <div v-else>
                <el-form-item label="菜单名称" prop="name" class="construction">
                  <el-input v-model="rule1Form.name" label-width="20px"></el-input>
                  <p class="caidan" @click="crea1tion">+新建菜单</p>
                </el-form-item>

                <div class="demo-dynamic" v-if="erji">
                  <el-form-item prop="email" label="二级菜单">
                    <el-input v-model="rule1Form.email"></el-input>
                  </el-form-item>
                  <el-form-item v-for="(domain, index) in rule1Form.domains" label="二级菜单" :key="domain.key" :prop="'domains.' + index + '.value'" :rules="[{ required: true, message: '请输入菜单名称', trigger: 'blur' },
                  { min: 0, max: 30, message: '不超过 30 个字符', trigger: 'blur' }]">
                    <el-input v-model="domain.value"></el-input><el-button @click.prevent="remove1Domain(domain)">删除</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="add1Domain">新增菜单</el-button>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submit1Form('rule1Form')">立即创建</el-button>
              <el-button @click="reset1Form('rule1Form')">重置</el-button>
            </span>
          </el-dialog>
          <p class="fwl-tle">5.岗位互动菜单（最多10个）<el-button class="newaddmenu" size="mini" plain @click="hu1dong">+新增</el-button></p>
          <div class="menupreview usermenupreview">
            <span>提升访客对话率，提高访客转化</span>
            <span>预览具体外观<img src="../../img/interact_menu_prev_pc.png"></span>
          </div>
          <div class="menupreview usermenuautohide" style="display: none;">
            <span>自动消失</span>
            <el-checkbox v-model="usermenuautohide">访客点击互动菜单或说话后</el-checkbox>
          </div>
          <div class="float-window-list">
            <el-table :data="table1Data" style="width: 100%" class="usermenucontent" :row-key="this.table1Data.mainid">
              <el-table-column width="23">
                <template>
                  <!-- <svg-icon class="mobileitem" icon-class="mobileitem" /> 备份 -->
                </template>
              </el-table-column>
              <el-table-column label="菜单名称" prop="date">
              </el-table-column>
              <el-table-column label="菜单类型" prop="name">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" @click="user1menuedit(scope.$index, scope.row)">编辑</el-button>
                  <el-button size="mini" type="text" @click="user1menudel(scope.$index, table1Data)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>


          <div class="endsave">
            <el-button size="mini" type="danger" @click="savebtn">保存更改</el-button>
            <el-button size="mini" @click="revertbtn">还原更改</el-button>
          </div>
        </div>
        <div class="float-window-right previewbox float-previewbox">
          <p class="fwl-tle">预览
            <span>
              <el-radio-group v-model="onofflinestyle" size="mini" @change="onoffline">
                <el-radio-button label="online">在线样式</el-radio-button>
                <el-radio-button label="offline">离线样式</el-radio-button>
              </el-radio-group>
            </span>
          </p>
          <div class="previewcontent">
            <div class="previewwindow" v-show="previewwindowshow == true">
              <div class="prewindowhead" :style="{ backgroundColor: themecolor }">
                <div class="kefuphoto">
                  <img src="../../img/kefu.png">
                </div>
                <div class="prewindowheadright">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" @click="previewwindowshow = false">
                    <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="rotate(180 12 12)">
                      <g id="Group-23">
                        <rect id="Rectangle-23" x="0" y="0" width="24" height="24"></rect>
                        <polygon id="Path-2" fill="currentColor" points="12 16 3 11 3 9 12 14 21 9 21 11"></polygon>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div v-show="onofflinestyle == 'online'" class="onlinecontent">
                <div class="chatcontent">
                  <p class="chattip"><span>欢迎您的咨询，期待为您服务！</span></p>
                  <div class="kefumes">
                    <div class="kefuphoto">
                      <img src="../../img/kefu.png">
                    </div>
                    <div class="kefumesctt">
                      <p><span>客服</span><span>17:03:39</span></p>
                      <span :style="{ backgroundColor: kfbubble, color: kfmes }">您好，请问有什么可以帮您的吗？</span>
                    </div>
                  </div>
                  <div class="usermes kefumes">
                    <div class="kefumesctt">
                      <p><span>我</span><span>17:03:42</span></p>
                      <span :style="{ backgroundColor: fkbubble, color: fkmes }">您好！</span>
                    </div>
                  </div>
                </div>
                <div class="usermenubox">
                  <div class="usermenuswiper">
                    <div class="usermenuson">
                      <div v-for="(item, index) in tableData" :key="index" class="usermenuitem">{{ item.date }}</div>
                    </div>
                  </div>
                </div>
                <el-input type="textarea" v-model="usermescontent" :rows="2" class="usermescontent"></el-input>
                <div class="sendfunc">
                  <a v-show="float_input_func.includes('fif1')" class="com_btn sendemoji">
                    <svg class="function-icon icon-face" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- 表情 -->
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Imported-Layers-Copy-7">
                          <rect id="Rectangle-3" x="0" y="0" width="24" height="24"></rect>
                          <path class="svgColor" d="M5,12 C5,15.8656805 8.1343195,19 12,19 C15.8656805,19 19,15.8656805 19,12 C19,8.1343195 15.8656805,5 12,5 C8.1343195,5 5,8.1343195 5,12 Z M3,12 C3,7.02975 7.02975,3 12,3 C16.97025,3 21,7.02975 21,12 C21,16.97025 16.97025,21 12,21 C7.02975,21 3,16.97025 3,12 Z" id="Combined-Shape" fill="currentColor" fill-rule="nonzero"></path>
                          <path class="svgColor" d="M9,8 C8.172,8 7.5,8.672 7.5,9.5 C7.5,10.328 8.172,11 9,11 C9.828,11 10.5,10.328 10.5,9.5 C10.5,8.672 9.828,8 9,8" id="Fill-2" fill="currentColor"></path>
                          <path class="svgColor" d="M15,8 C14.172,8 13.5,8.672 13.5,9.5 C13.5,10.328 14.172,11 15,11 C15.828,11 16.5,10.328 16.5,9.5 C16.5,8.672 15.828,8 15,8" id="Fill-2-Copy" fill="currentColor"></path>
                          <path class="svgColor" d="M9.55730755,18.9802954 C12.5582692,18.9802954 15.0860829,16.7646048 15.4989445,13.8196019 C15.5756198,13.2726656 15.1943979,12.7671285 14.6474616,12.6904532 C14.1005252,12.6137779 13.5949881,12.9949998 13.5183128,13.5419361 C13.2434436,15.5026194 11.5576081,16.9802954 9.55730755,16.9802954 C9.0050228,16.9802954 8.55730755,17.4280106 8.55730755,17.9802954 C8.55730755,18.5325801 9.0050228,18.9802954 9.55730755,18.9802954 Z" id="Oval-26" fill="currentColor" fill-rule="nonzero" transform="translate(12.033030, 15.830470) rotate(39.000000) translate(-12.033030, -15.830470) "></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a v-show="float_input_func.includes('fif2')" class="com_btn screenshots">
                    <svg class="function-icon icon-cut" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- 截图 -->
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-15">
                          <g id="Group-81-Copy-6">
                            <rect id="Rectangle-81" x="0" y="0" width="24" height="24"></rect>
                          </g>
                          <g class="svgColor" transform="translate(4.000000, 4.000000)" fill-rule="nonzero" fill="currentColor">
                            <path d="M3.5,14 C4.32842712,14 5,13.3284271 5,12.5 C5,11.6715729 4.32842712,11 3.5,11 C2.67157288,11 2,11.6715729 2,12.5 C2,13.3284271 2.67157288,14 3.5,14 Z M9.5510355,7.32174556 L11.2561548,9.22746708 C11.6426526,9.08048219 12.0619275,9 12.5,9 C14.4329966,9 16,10.5670034 16,12.5 C16,14.4329966 14.4329966,16 12.5,16 C10.5670034,16 9,14.4329966 9,12.5 C9,12.1413991 9.05393,11.7953943 9.1541219,11.4696536 L8,9.7 L6.8458781,11.4696536 C6.94607,11.7953943 7,12.1413991 7,12.5 C7,14.4329966 5.43299662,16 3.5,16 C1.56700338,16 0,14.4329966 0,12.5 C0,10.5670034 1.56700338,9 3.5,9 C3.93807254,9 4.35734745,9.08048219 4.74384524,9.22746708 L6.4489645,7.32174556 L2,0.5 L3,0 L8,5.58823529 L13,0 L14,0.5 L9.5510355,7.32174556 Z M12.5,14 C13.3284271,14 14,13.3284271 14,12.5 C14,11.6715729 13.3284271,11 12.5,11 C11.6715729,11 11,11.6715729 11,12.5 C11,13.3284271 11.6715729,14 12.5,14 Z M8,9 C8.55228475,9 9,8.55228475 9,8 C9,7.44771525 8.55228475,7 8,7 C7.44771525,7 7,7.44771525 7,8 C7,8.55228475 7.44771525,9 8,9 Z" id="Combined-Shape"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a v-show="float_input_func.includes('fif3')" class="com_btn file-fs">
                    <svg class="fileUpload" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path class="svgColor" fill="currentColor" d="M5,18 L19.0010872,18 C19.0005141,17.9998702 19.0001517,14.6674569 19,8.00276013 C19,8.00181211 16.294032,8.00089207 10.8820961,8 L9.88209609,6 L5.00673767,6 C5.00250242,6 5.00025653,10 5,18 Z M3,5.99188419 L3,4 L11.1181641,4 L12.1181641,6 L21,6 L21,8.00276013 L21,17.9972399 L21,20 L3,20 L3,18.0081158 L3,5.99188419 Z M13,17 L13,12.6666667 L15,14 L15,12 L12,10 L9,12 L9,14 L11,12.6666667 L11,17 L13,17 Z"></path>
                    </svg>
                  </a>
                  <a v-show="float_input_func.includes('fif4')" class="com_btn download">
                    <svg class="function-icon download" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- 下载 -->
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-81-Copy-17">
                          <rect id="Rectangle-81" x="0" y="0" width="24" height="24"></rect>
                          <path d="M14.5,4.5 L9.5,4.5 L9.5,10.5 L6.20710678,10.5 L12,16.2928932 L17.7928932,10.5 L14.5,10.5 L14.5,4.5 Z" id="Shape" stroke="#8FA1B3"></path>
                          <polygon class="svgColor" id="Path" fill="currentColor" points="5 20 19 20 19 19 5 19"></polygon>
                        </g>
                        <g id="Group-81-Copy-16" fill-rule="nonzero" fill="currentColor">
                          <path class="svgColor" d="M13,12 L13,6 L11,6 L11,12 L9.80004883,12 L12,14.2001953 L14.1999512,12 L13,12 Z M15,10 L19,10 L12,17 L5,10 L9,10 L9,4 L15,4 L15,10 Z M5,20 L5,18 L19,18 L19,20 L5,20 Z" id="Combined-Shape"></path>
                        </g>
                        <g id="Group-81-Copy-19" transform="translate(21.000000, -1.000000)">
                          <rect id="Rectangle-81" x="0" y="0" width="24" height="24"></rect>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a v-show="float_input_func.includes('fif5')" class="com_btn eval">
                    <svg class="function-icon evaluation-icon function-icon-eval" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g class="svgStroke" id="Group-21" stroke="currentColor" stroke-width="2">
                          <path d="M15.9619362,17.4531374 L15.2052738,13.0414566 L18.4105475,9.91708685 L13.9809681,9.27343129 L12,5.25955146 L10.0190319,9.27343129 L5.5894525,9.91708685 L8.79472625,13.0414566 L8.03806376,17.4531374 L12,15.3702243 L15.9619362,17.4531374 Z" id="Star"></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <el-button type="primary" size="mini" class="sendmesbtn" :style="{ backgroundColor: sendbtn }">发送</el-button>
              </div>
              <div v-show="onofflinestyle == 'offline'" class="offlinecontent">
                <p>您好，当前客服已离线，您可以留下联系方式，工作人员会第一时间给予你回复！</p>
                <div>
                  <label><span>*</span>姓名</label>
                  <el-input v-model="offlinename" size="mini"></el-input>
                </div>
                <div>
                  <label><span>*</span>手机</label>
                  <el-input v-model="offlinephone" size="mini" maxlength="11"></el-input>
                </div>
                <el-button type="primary" size="mini" class="offlinesendmesbtn" :style="{ backgroundColor: sendbtn }">提交留言</el-button>
              </div>
            </div>
            <div class="previewbtnboxs" v-show="previewwindowshow == false">
              <div v-show="float_button_style == 'btns1'" @click="previewbtnclick" class="previewbtnone" :style="{ backgroundColor: themecolor, right: btnpxrnum + btnpunit, bottom: btnpxbnum + btnpunit }">
                <svg v-show="onofflinestyle == 'online'" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-7">
                      <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                      <path d="M12.5,21 C18.2989899,21 23,16.7467051 23,11.5 C23,6.25329488 18.2989899,2 12.5,2 C6.70101013,2 2,6.25329488 2,11.5 C2,14.1233526 3.17525253,16.4983526 5.0753788,18.2175144 C5.56085249,18.6567525 4.09364444,21.0531789 4.66624315,21.3999973 C5.23438584,21.7441167 7.84171812,20.0393974 8.4809025,20.2792006 C9.71888634,20.7436557 11.0763558,21 12.5,21 Z M18.275,13 C19.1448485,13 19.85,12.3284271 19.85,11.5 C19.85,10.6715729 19.1448485,10 18.275,10 C17.4051515,10 16.7,10.6715729 16.7,11.5 C16.7,12.3284271 17.4051515,13 18.275,13 Z M13.025,13 C13.8948485,13 14.6,12.3284271 14.6,11.5 C14.6,10.6715729 13.8948485,10 13.025,10 C12.1551515,10 11.45,10.6715729 11.45,11.5 C11.45,12.3284271 12.1551515,13 13.025,13 Z M7.775,13 C8.64484848,13 9.35,12.3284271 9.35,11.5 C9.35,10.6715729 8.64484848,10 7.775,10 C6.90515152,10 6.2,10.6715729 6.2,11.5 C6.2,12.3284271 6.90515152,13 7.775,13 Z" id="Combined-Shape" fill="currentColor"></path>
                    </g>
                  </g>
                </svg>
                <i v-show="onofflinestyle == 'online'">1</i>
                <svg v-show="onofflinestyle == 'offline'" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-29">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                      <path d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 L20,4 Z M20,18 L4,18 L4,8 L12,13 L20,8 L20,18 L20,18 Z M12,11 L4,6 L20,6 L12,11 L12,11 Z" id="Shape" fill="currentColor"></path>
                    </g>
                  </g>
                </svg>
                <span v-show="onofflinestyle == 'online'">{{ onlinetxt }}</span>
                <span v-show="onofflinestyle == 'offline'">{{ offlinetxt }}</span>
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="rotate(180 12 12)">
                    <g id="Group-23">
                      <rect id="Rectangle-23" x="0" y="0" width="24" height="24"></rect>
                      <polygon id="Path-2" fill="currentColor" points="12 16 3 11 3 9 12 14 21 9 21 11"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div v-show="float_button_style == 'btns2'" @click="previewbtnclick" class="previewbtntwo previewbtnone" :style="{ backgroundColor: themecolor, right: btnpxrnum + btnpunit, bottom: btnpxbnum + btnpunit }">
                <svg v-show="onofflinestyle == 'online'" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-7">
                      <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                      <path d="M12.5,21 C18.2989899,21 23,16.7467051 23,11.5 C23,6.25329488 18.2989899,2 12.5,2 C6.70101013,2 2,6.25329488 2,11.5 C2,14.1233526 3.17525253,16.4983526 5.0753788,18.2175144 C5.56085249,18.6567525 4.09364444,21.0531789 4.66624315,21.3999973 C5.23438584,21.7441167 7.84171812,20.0393974 8.4809025,20.2792006 C9.71888634,20.7436557 11.0763558,21 12.5,21 Z M18.275,13 C19.1448485,13 19.85,12.3284271 19.85,11.5 C19.85,10.6715729 19.1448485,10 18.275,10 C17.4051515,10 16.7,10.6715729 16.7,11.5 C16.7,12.3284271 17.4051515,13 18.275,13 Z M13.025,13 C13.8948485,13 14.6,12.3284271 14.6,11.5 C14.6,10.6715729 13.8948485,10 13.025,10 C12.1551515,10 11.45,10.6715729 11.45,11.5 C11.45,12.3284271 12.1551515,13 13.025,13 Z M7.775,13 C8.64484848,13 9.35,12.3284271 9.35,11.5 C9.35,10.6715729 8.64484848,10 7.775,10 C6.90515152,10 6.2,10.6715729 6.2,11.5 C6.2,12.3284271 6.90515152,13 7.775,13 Z" id="Combined-Shape" fill="currentColor"></path>
                    </g>
                  </g>
                </svg>
                <i v-show="onofflinestyle == 'online'">1</i>
                <svg v-show="onofflinestyle == 'offline'" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-29">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                      <path d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 L20,4 Z M20,18 L4,18 L4,8 L12,13 L20,8 L20,18 L20,18 Z M12,11 L4,6 L20,6 L12,11 L12,11 Z" id="Shape" fill="currentColor"></path>
                    </g>
                  </g>
                </svg>
                <span v-show="onofflinestyle == 'online'">{{ onlinetxt }}</span>
                <span v-show="onofflinestyle == 'offline'">{{ offlinetxt }}</span>
              </div>
              <div v-show="float_button_style == 'btns3'" @click="previewbtnclick" class="previewbtnthree previewbtnone" :style="{ backgroundColor: themecolor, right: btnpxrnum + btnpunit, bottom: btnpxbnum + btnpunit }">
                <svg v-show="onofflinestyle == 'online'" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-7">
                      <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                      <path d="M12.5,21 C18.2989899,21 23,16.7467051 23,11.5 C23,6.25329488 18.2989899,2 12.5,2 C6.70101013,2 2,6.25329488 2,11.5 C2,14.1233526 3.17525253,16.4983526 5.0753788,18.2175144 C5.56085249,18.6567525 4.09364444,21.0531789 4.66624315,21.3999973 C5.23438584,21.7441167 7.84171812,20.0393974 8.4809025,20.2792006 C9.71888634,20.7436557 11.0763558,21 12.5,21 Z M18.275,13 C19.1448485,13 19.85,12.3284271 19.85,11.5 C19.85,10.6715729 19.1448485,10 18.275,10 C17.4051515,10 16.7,10.6715729 16.7,11.5 C16.7,12.3284271 17.4051515,13 18.275,13 Z M13.025,13 C13.8948485,13 14.6,12.3284271 14.6,11.5 C14.6,10.6715729 13.8948485,10 13.025,10 C12.1551515,10 11.45,10.6715729 11.45,11.5 C11.45,12.3284271 12.1551515,13 13.025,13 Z M7.775,13 C8.64484848,13 9.35,12.3284271 9.35,11.5 C9.35,10.6715729 8.64484848,10 7.775,10 C6.90515152,10 6.2,10.6715729 6.2,11.5 C6.2,12.3284271 6.90515152,13 7.775,13 Z" id="Combined-Shape" fill="currentColor"></path>
                    </g>
                  </g>
                </svg>
                <i v-show="onofflinestyle == 'online'">1</i>
                <svg v-show="onofflinestyle == 'offline'" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="pc端样式自定义" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-29">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                      <path d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 L20,4 Z M20,18 L4,18 L4,8 L12,13 L20,8 L20,18 L20,18 Z M12,11 L4,6 L20,6 L12,11 L12,11 Z" id="Shape" fill="currentColor"></path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="新窗口" name="newwindow" class="new-window">
        <div class="float-window-left">
          <p class="fwl-tle">1.聊天框设置</p>
          <div class="float-window-list" style="display: none;">
            <label class="float-window-label">窗口尺寸</label>
            <div>
              <el-radio-group v-model="new_window_size" class="float-window-size new-window-size" @change="nwsclick">
                <el-radio label="800x600">800x600 像素</el-radio>
                <el-radio label="autosize">自适应尺寸</el-radio>
              </el-radio-group>
              <p class="graytxt">自适应尺寸的大小与浏览器窗口尺寸实时一致</p>
            </div>
          </div>
          <div class="float-window-list">
            <label class="float-window-label">签名</label>
            <el-input v-model="sign" size="mini" class="sign"></el-input>
          </div>
          <div class="float-window-list">
            <label class="float-window-label">颜色</label>
            <div class="windowsize">
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="themecolor" @active-change="themecolorchange"></el-color-picker>
                <span>头部背景</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="sendbtn" @active-change="sendbtnchange"></el-color-picker>
                <span>发送按钮</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="kfbubble" @active-change="kfbubblechange"></el-color-picker>
                <span>客服气泡</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="kfmes" @active-change="kfmeschange"></el-color-picker>
                <span>客服消息</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="fkbubble" @active-change="fkbubblechange"></el-color-picker>
                <span>访客气泡</span>
              </div>
              <div class="themecolor">
                <el-color-picker class="themecoloredit" v-model="fkmes" @active-change="fkmeschange"></el-color-picker>
                <span>访客消息</span>
              </div>
            </div>
          </div>
          <div class="float-window-list" style="display: none;">
            <label class="float-window-label">访客发送功能</label>
            <div>
              <el-checkbox-group v-model="float_input_func" class="float-input-func" @change="fifclick">
                <el-checkbox label="fif1">发送表情</el-checkbox>
                <el-checkbox label="fif2">截图</el-checkbox>
                <el-checkbox label="fif3">发送文件及图片</el-checkbox>
                <el-checkbox label="fif4">保存聊天记录</el-checkbox>
                <el-checkbox label="fif5">评价</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div style="display: none;">
            <p class="fwl-tle">2.右侧区域</p>
            <div class="float-window-list">
              <label class="float-window-label">关于我们</label>
              <el-input type="textarea" :rows="16" v-model="endeditorText" class="aboutedit"></el-input>
            </div>
            <div class="float-window-list" style="display: none;">
              <label class="float-window-label">显示客服名片</label>
              <div>
                <el-checkbox v-model="kefuvisitingcard">启用</el-checkbox>
              </div>
            </div>
          </div>
          <p class="fwl-tle">3.右下角广告区域</p>
          <span class="new-window-threetip">最多 3 张图片（支持JPG、PNG、GIF）</span>
          <el-upload :class="upimgbtnshow" class="upimgbox" ref="upbannerimgs" :action="upimgpost" list-type="picture-card" name="image" :on-success="bannerimgsuccess" :on-remove="removebannerimg" :before-upload="beforeAvatarUpload" :on-error="bannerimgerror" :file-list="newrightbannersave">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-input type="textarea" :rows="5" placeholder="请输入右侧广告文本内容" class="bannerimgtxt" v-model="endeditorText">
          </el-input>
          <div class="endsave">
            <el-button size="mini" type="danger" @click="savebtn">保存更改</el-button>
            <el-button size="mini" @click="revertbtn">还原更改</el-button>
          </div>
        </div>
        <div class="float-window-right previewbox">
          <p class="fwl-tle">预览</p>
          <div class="newpreviewcontent previewcontent">
            <div class="newpreviewhead" :style="{ backgroundColor: themecolor }">
              <img src="../../img/kefu.png">
              <span>{{ sign }}</span>
            </div>
            <div class="newpreviewchat">
              <div class="newpreviewchatleft">
                <div class="newpreviewchatctt">
                  <div class="chatcontent">
                    <p class="chattip"><span>欢迎您的咨询，期待为您服务！</span></p>
                    <div class="kefumes">
                      <div class="kefuphoto">
                        <img src="../../img/kefu.png">
                      </div>
                      <div class="kefumesctt">
                        <p><span>客服</span><span>17:03:39</span></p>
                        <span :style="{ backgroundColor: kfbubble, color: kfmes }">您好，请问有什么可以帮您的吗？</span>
                      </div>
                    </div>
                    <div class="usermes kefumes">
                      <div class="kefumesctt">
                        <p><span>我</span><span>17:03:42</span></p>
                        <span :style="{ backgroundColor: fkbubble, color: fkmes }">您好！</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="newpreviewsendfunc">
                  <div class="sendfunc">
                    <a v-show="float_input_func.includes('fif1')" class="com_btn sendemoji">
                      <svg class="function-icon icon-face" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- 表情 -->
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Imported-Layers-Copy-7">
                            <rect id="Rectangle-3" x="0" y="0" width="24" height="24"></rect>
                            <path class="svgColor" d="M5,12 C5,15.8656805 8.1343195,19 12,19 C15.8656805,19 19,15.8656805 19,12 C19,8.1343195 15.8656805,5 12,5 C8.1343195,5 5,8.1343195 5,12 Z M3,12 C3,7.02975 7.02975,3 12,3 C16.97025,3 21,7.02975 21,12 C21,16.97025 16.97025,21 12,21 C7.02975,21 3,16.97025 3,12 Z" id="Combined-Shape" fill="currentColor" fill-rule="nonzero"></path>
                            <path class="svgColor" d="M9,8 C8.172,8 7.5,8.672 7.5,9.5 C7.5,10.328 8.172,11 9,11 C9.828,11 10.5,10.328 10.5,9.5 C10.5,8.672 9.828,8 9,8" id="Fill-2" fill="currentColor"></path>
                            <path class="svgColor" d="M15,8 C14.172,8 13.5,8.672 13.5,9.5 C13.5,10.328 14.172,11 15,11 C15.828,11 16.5,10.328 16.5,9.5 C16.5,8.672 15.828,8 15,8" id="Fill-2-Copy" fill="currentColor"></path>
                            <path class="svgColor" d="M9.55730755,18.9802954 C12.5582692,18.9802954 15.0860829,16.7646048 15.4989445,13.8196019 C15.5756198,13.2726656 15.1943979,12.7671285 14.6474616,12.6904532 C14.1005252,12.6137779 13.5949881,12.9949998 13.5183128,13.5419361 C13.2434436,15.5026194 11.5576081,16.9802954 9.55730755,16.9802954 C9.0050228,16.9802954 8.55730755,17.4280106 8.55730755,17.9802954 C8.55730755,18.5325801 9.0050228,18.9802954 9.55730755,18.9802954 Z" id="Oval-26" fill="currentColor" fill-rule="nonzero" transform="translate(12.033030, 15.830470) rotate(39.000000) translate(-12.033030, -15.830470) "></path>
                          </g>
                        </g>
                      </svg>
                    </a>
                    <a v-show="float_input_func.includes('fif2')" class="com_btn screenshots">
                      <svg class="function-icon icon-cut" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- 截图 -->
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Group-15">
                            <g id="Group-81-Copy-6">
                              <rect id="Rectangle-81" x="0" y="0" width="24" height="24"></rect>
                            </g>
                            <g class="svgColor" transform="translate(4.000000, 4.000000)" fill-rule="nonzero" fill="currentColor">
                              <path d="M3.5,14 C4.32842712,14 5,13.3284271 5,12.5 C5,11.6715729 4.32842712,11 3.5,11 C2.67157288,11 2,11.6715729 2,12.5 C2,13.3284271 2.67157288,14 3.5,14 Z M9.5510355,7.32174556 L11.2561548,9.22746708 C11.6426526,9.08048219 12.0619275,9 12.5,9 C14.4329966,9 16,10.5670034 16,12.5 C16,14.4329966 14.4329966,16 12.5,16 C10.5670034,16 9,14.4329966 9,12.5 C9,12.1413991 9.05393,11.7953943 9.1541219,11.4696536 L8,9.7 L6.8458781,11.4696536 C6.94607,11.7953943 7,12.1413991 7,12.5 C7,14.4329966 5.43299662,16 3.5,16 C1.56700338,16 0,14.4329966 0,12.5 C0,10.5670034 1.56700338,9 3.5,9 C3.93807254,9 4.35734745,9.08048219 4.74384524,9.22746708 L6.4489645,7.32174556 L2,0.5 L3,0 L8,5.58823529 L13,0 L14,0.5 L9.5510355,7.32174556 Z M12.5,14 C13.3284271,14 14,13.3284271 14,12.5 C14,11.6715729 13.3284271,11 12.5,11 C11.6715729,11 11,11.6715729 11,12.5 C11,13.3284271 11.6715729,14 12.5,14 Z M8,9 C8.55228475,9 9,8.55228475 9,8 C9,7.44771525 8.55228475,7 8,7 C7.44771525,7 7,7.44771525 7,8 C7,8.55228475 7.44771525,9 8,9 Z" id="Combined-Shape"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </a>
                    <a v-show="float_input_func.includes('fif3')" class="com_btn file-fs">
                      <svg class="fileUpload" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path class="svgColor" fill="currentColor" d="M5,18 L19.0010872,18 C19.0005141,17.9998702 19.0001517,14.6674569 19,8.00276013 C19,8.00181211 16.294032,8.00089207 10.8820961,8 L9.88209609,6 L5.00673767,6 C5.00250242,6 5.00025653,10 5,18 Z M3,5.99188419 L3,4 L11.1181641,4 L12.1181641,6 L21,6 L21,8.00276013 L21,17.9972399 L21,20 L3,20 L3,18.0081158 L3,5.99188419 Z M13,17 L13,12.6666667 L15,14 L15,12 L12,10 L9,12 L9,14 L11,12.6666667 L11,17 L13,17 Z"></path>
                      </svg>
                    </a>
                    <a v-show="float_input_func.includes('fif4')" class="com_btn download">
                      <svg class="function-icon download" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- 下载 -->
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Group-81-Copy-17">
                            <rect id="Rectangle-81" x="0" y="0" width="24" height="24"></rect>
                            <path d="M14.5,4.5 L9.5,4.5 L9.5,10.5 L6.20710678,10.5 L12,16.2928932 L17.7928932,10.5 L14.5,10.5 L14.5,4.5 Z" id="Shape" stroke="#8FA1B3"></path>
                            <polygon class="svgColor" id="Path" fill="currentColor" points="5 20 19 20 19 19 5 19"></polygon>
                          </g>
                          <g id="Group-81-Copy-16" fill-rule="nonzero" fill="currentColor">
                            <path class="svgColor" d="M13,12 L13,6 L11,6 L11,12 L9.80004883,12 L12,14.2001953 L14.1999512,12 L13,12 Z M15,10 L19,10 L12,17 L5,10 L9,10 L9,4 L15,4 L15,10 Z M5,20 L5,18 L19,18 L19,20 L5,20 Z" id="Combined-Shape"></path>
                          </g>
                          <g id="Group-81-Copy-19" transform="translate(21.000000, -1.000000)">
                            <rect id="Rectangle-81" x="0" y="0" width="24" height="24"></rect>
                          </g>
                        </g>
                      </svg>
                    </a>
                    <a v-show="float_input_func.includes('fif5')" class="com_btn eval">
                      <svg class="function-icon evaluation-icon function-icon-eval" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g class="svgStroke" id="Group-21" stroke="currentColor" stroke-width="2">
                            <path d="M15.9619362,17.4531374 L15.2052738,13.0414566 L18.4105475,9.91708685 L13.9809681,9.27343129 L12,5.25955146 L10.0190319,9.27343129 L5.5894525,9.91708685 L8.79472625,13.0414566 L8.03806376,17.4531374 L12,15.3702243 L15.9619362,17.4531374 Z" id="Star"></path>
                          </g>
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="newusermescontent">
                  <el-input type="textarea" v-model="usermescontent" :rows="2" class="usermescontent" placeholder="请输入文字"></el-input>
                </div>
                <div class="newsendmesbtn">
                  <el-button type="primary" size="mini" class="sendmesbtn" :style="{ backgroundColor: sendbtn }">发送</el-button>
                </div>
              </div>
              <div class="newpreviewchatright">
                <div class="npcrtle">
                  <span class="abouttle">关于我们</span>
                </div>
                <div v-show="this.newrightbanner.length == 0" class="newrightbanner">
                  <img src="https://chat.xuekao123.com/uploads/20230311/9e115a3f8e1d5e8543fc75e0a82f2551.png">
                </div>
                <div v-show="this.newrightbanner.length > 0" class="newrightbanner swiper-container newrightbannerswiper swiper-no-swiping">
                  <div class="swiper-wrapper">
                    <img class="swiper-slide" v-for="(v, i) in newrightbannersave" :key="i" :src="v.url">
                  </div>
                </div>
                <div class="npcrcontent">
                  <span>本网站致力于打造人才技能+招聘就业的综合性人才市场服务平台。截止目前...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {stylesave, getsite, getStyle} from "@/api/jiekou";
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css'



export default {
  name: "XianmuFkPC",

  data() {
    return {
      consultation: [
        {
          title: "啊哈哈哈哈",
          content: "ahhhhh",
        },
        {
          title: "哎呀呀呀呀",
          content: "ayyyyyy",
        },
      ],
      getstyledata: {},
      stylecontentshow: 0,
      tctitle: "设置显示字段",   //弹窗标题

      // 新建客户菜单弹窗
      ruleForm: {
        name: '',
        resource: 1,
        desc: '',
        domains: [{
          value: ''
        }],
        email: ''
      },
      // 新建岗位菜单弹窗
      rule1Form: {
        name: '',
        resource: 2,
        desc: '',
        domains: [{
          value: ''
        }],
        email: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入菜单名称', trigger: 'blur'},
          {min: 0, max: 30, message: '不超过 30 个字符', trigger: 'blur'}
        ],
        resource: [
          {required: true, message: '菜单类型', trigger: 'change'}
        ],
        desc: [
          {required: true, message: '请填写回复内容', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入菜单名称', trigger: 'blur'},
          {min: 0, max: 30, message: '不超过 30个字符', trigger: 'blur'}
        ]
      },
      erji: false,
      redactId: "",   //保存点击编辑的id
      // 下拉框选择站点列表
      options: [],
      optionsId: "",
      bannerimgtxt: "",
      upimgbtnshow: "",
      upimgitem: [],
      upimgpost: "https://chat.xuekao123.com/api/style/setting/upload",
      dialogImageUrl: '',
      dialogVisible: false,
      dialog1Visible: false,
      chatway: "new",
      kficonstyle: "kfbtn1",
      kefuicononoff: "",
      newrightbanner: [],
      newrightbannersave: [],
      adimginput1: "",
      adimginput2: "",
      adimginput3: "",
      kefuvisitingcard: false,
      editorText: '',
      endeditorText: '',
      sign: "为您在线解答售前(5x8)、售后咨询(7x24)服务",
      new_window_size: "800x600",
      offlinename: "",
      offlinephone: "",
      usermescontent: "",
      previewwindowshow: false,
      onofflinestyle: "online",
      /* 客户互动菜单数据 */
      tableData: [{
        date: '您好',
        name: '快问快答',
        desc: "您好",
        resource: 1
      }, {
        date: '请问呢',
        name: '快问快答',
        desc: "您好",
        resource: 1
      }],
      /* 岗位互动菜单数据 */
      table1Data: [
        // {
        //   date: '您好',
        //   name: '快问快答',
        //   desc: "您好",
        //   resource: 1
        // }, {
        //   date: '请问呢',
        //   name: '快问快答',
        //   desc: "您好",
        //   resource: 1
        // }
      ],
      testtableData: [
        {
          mianid: 1,
          date: '2016-05-02',
          name: '王小虎1',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          mianid: 2,
          date: '2016-05-04',
          name: '王小虎2',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          mianid: 3,
          date: '2016-05-01',
          name: '王小虎3',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          mianid: 4,
          date: '2016-05-02',
          name: '王小虎4',
          address: '上海市普陀区金沙江路 1518 弄'
        }
      ],
      usermenuautohide: false,
      kfbubble: "#eff3f6",
      kfmes: "#3a3c4c",
      fkbubble: "#1f8ce8",
      fkmes: "#ffffff",
      sendbtn: "#1f8ce8",
      onlinetxt: "和我们在线交谈!",
      offlinetxt: "给我们留言吧!",
      themecolor: "#0A83F5",
      btnpctbox: true,
      btnpixbox: false,
      btnpxrnum: 0,
      btnpxbnum: 0,
      btnpunit: "px",
      pcstyle: "floatwindow",
      float_button_style: 'btns1',
      float_button_position: 'btnp1',
      float_window_size: "",
      float_input_func: ["fif1", "fif2", "fif3", "fif4", "fif5",],
      btnpx: '',
      btnpy: '',
      btnpxs: [
        {
          value: 'rm',
          label: '右边距'
        },
        {
          value: 'lm',
          label: '左边距'
        }
      ],
      btnpys: [
        {
          value: 'bm',
          label: '下边距'
        },
        {
          value: 'tm',
          label: '上边距'
        }
      ],
    };
  },

  updated() {
    var newrightbannerswiper = new Swiper('.newrightbannerswiper', {
      observer: true,
      autoplay: true
    })
  },

  mounted() {
    this.GetSite()
  },

  created() {
    this.btnpx = this.btnpxs[0].value
    this.btnpy = this.btnpys[0].value
  },

  methods: {
    // 获取站点信息接口
    async GetSite() {
      let res = await getsite()
      console.log(res)
      this.options = []
      res.data.data.forEach(val => {
        let arr = {}
        arr.value = val.id
        arr.label = val.site_name
        this.options.push(arr)
      })
    },
    async select(value) {
      this.consultation = []
      this.newrightbanner = []
      this.newrightbannersave = []
      this.optionsId = value[0]
      let sitestyle = await getStyle({
        site_id: value[0],
        type: "0"
      })
      if (sitestyle.data.data.length) {
        this.getstyledata = "";
        this.getstyledata = JSON.parse(sitestyle.data.data[0].setting)
        this.coverstyle()
      }
      this.stylecontentshow = 1
    },
    async revertbtn() {
      this.select([this.optionsId])
    },
    coverstyle() {
      if (this.getstyledata.hasOwnProperty('chatbtn')) {
        this.float_button_style = this.getstyledata.chatbtn.btnstyle
      }
      if (this.getstyledata.hasOwnProperty('btnposition')) {
        this.float_button_position = this.getstyledata.btnposition.pixelorpercentage
        this.btnpx = this.getstyledata.btnposition.leftrightmargin
        this.btnpy = this.getstyledata.btnposition.topbottommargin
        this.btnpxrnum = this.getstyledata.btnposition.onemargin
        this.btnpxbnum = this.getstyledata.btnposition.twomargin
      }
      if (this.getstyledata.hasOwnProperty('themetxt')) {
        this.themecolor = this.getstyledata.themetxt.maincolor
        this.onlinetxt = this.getstyledata.themetxt.onlinetxt
        this.offlinetxt = this.getstyledata.themetxt.offlinetxt
      }
      if (this.getstyledata.hasOwnProperty('chatsetting')) {
        this.kfbubble = this.getstyledata.chatsetting.kfbubble
        this.kfmes = this.getstyledata.chatsetting.kfmes
        this.fkbubble = this.getstyledata.chatsetting.fkbubble
        this.fkmes = this.getstyledata.chatsetting.fkmes
        this.sendbtn = this.getstyledata.chatsetting.sendbtn
      }
      if (this.getstyledata.hasOwnProperty('newwindos')) {
        this.sign = this.getstyledata.newwindos.kfsign
        this.newrightbanner = this.getstyledata.newwindos.rightbannerimg
        this.endeditorText = this.getstyledata.newwindos.rightadtxt
      }
      if (this.getstyledata.hasOwnProperty('menu')) {
        this.tableData = this.getstyledata.menu.Text
      }
      if (this.getstyledata.hasOwnProperty('station')) {
        this.table1Data = this.getstyledata.station.TexT
      }
      if (this.getstyledata.hasOwnProperty('consultationmenu')) {
        this.consultation = this.getstyledata.consultationmenu.fastconsult
      }
      this.newrightbanner.forEach((v) => {
        this.newrightbannersave.push({url: v})
      });
      this.newrightbannersave.length == 3 ? this.upimgbtnshow = "bannerimghide" : this.upimgbtnshow = ""
    },
    // 点击新建客户互动菜单出现弹窗
    hudong() {
      this.dialogVisible = true
      this.tctitle = "设置显示字段"
      this.ruleForm = {
        name: '',
        resource: 1,
        desc: '',
        domains: [{
          value: ''
        }],
        email: ''
      }
    },
    // 点击新建岗位互动菜单出现弹窗
    hu1dong() {
      this.dialog1Visible = true
      this.tctitle = "设置显示字段"
      this.rule1Form = {
        name: '',
        resource: 2,
        desc: '',
        domains: [{
          value: ''
        }],
        email: ''
      }
    },
    // 创建客户互动菜单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.dialogVisible = false
          if (this.tctitle == "设置显示字段") {
            let res = {}
            if (this.ruleForm.resource == 1) {
              res.date = this.ruleForm.name
              res.name = "快问快答"
              res.resource = this.ruleForm.resource
              res.desc = this.ruleForm.desc
            } else {
              res.date = this.ruleForm.name
              res.name = "快速提问"
              res.resource = this.ruleForm.resource
              res.email = this.ruleForm.email
              res.domains = this.ruleForm.domains
            }
            this.tableData.push(res)
          } else {
            let res = {}
            if (this.ruleForm.resource == 1) {
              res.date = this.ruleForm.name
              res.name = "快问快答"
              res.resource = this.ruleForm.resource
              res.desc = this.ruleForm.desc
            } else {
              res.date = this.ruleForm.name
              res.name = "快速提问"
              res.resource = this.ruleForm.resource
              res.email = this.ruleForm.email
              res.domains = this.ruleForm.domains
            }
            this.tableData.splice(this.redactId, 1, res)
          }
        } else {
          return false;
        }
      });
    },
    // 创建岗位互动菜单
    submit1Form(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.dialog1Visible = false
          if (this.tctitle == "设置显示字段") {
            let res = {}
            if (this.rule1Form.resource == 1) {
              res.date = this.rule1Form.name
              res.name = "快问快答"
              res.resource = this.rule1Form.resource
              res.desc = this.rule1Form.desc
            } else {
              res.date = this.rule1Form.name
              res.name = "快速提问"
              res.resource = this.rule1Form.resource
              res.email = this.rule1Form.email
              res.domains = this.rule1Form.domains
            }
            this.table1Data.push(res)
          } else {
            let res = {}
            if (this.rule1Form.resource == 1) {
              res.date = this.rule1Form.name
              res.name = "快问快答"
              res.resource = this.rule1Form.resource
              res.desc = this.rule1Form.desc
            } else {
              res.date = this.rule1Form.name
              res.name = "快速提问"
              res.resource = this.rule1Form.resource
              res.email = this.rule1Form.email
              res.domains = this.rule1Form.domains
            }
            this.table1Data.splice(this.redactId, 1, res)
          }
        } else {
          return false;
        }
      });
    },
    //客户重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //岗位重置
    reset1Form(formName) {
      this.$refs[formName].resetFields();
    },
    // 删除客户二级菜单
    removeDomain(item) {
      var index = this.ruleForm.domains.indexOf(item)
      if (index !== -1) {
        this.ruleForm.domains.splice(index, 1)
      }
    },
    // 删除岗位二级菜单
    remove1Domain(item) {
      var index = this.rule1Form.domains.indexOf(item)
      if (index !== -1) {
        this.rule1Form.domains.splice(index, 1)
      }
    },
    //   新增客户二级菜单
    addDomain() {
      this.ruleForm.domains.push({
        value: '',
        key: Date.now()
      });
    },
    //   新增岗位二级菜单
    add1Domain() {
      this.rule1Form.domains.push({
        value: '',
        key: Date.now()
      });
    },
    // 新建客户菜单
    creation() {
      if (this.erji == false) {
        this.erji = true
      } else {
        this.erji = false
      }
    },
    // 新建岗位菜单
    crea1tion() {
      if (this.erji == false) {
        this.erji = true
      } else {
        this.erji = false
      }
    },




    beforeAvatarUpload(file) {
      const isform = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt3M = file.size / 1024 / 1024 < 5;
      if (!isform) {
        this.$message.error('上传头像图片只能是 jpg,png,gif 格式!');
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return isform && isLt3M;
    },
    bannerimgsuccess(val) {
      this.$message.success('上传成功！');
      this.newrightbanner.push(val.data)
      this.newrightbannersave.push({url: val.data})
      this.newrightbannersave.length == 3 ? this.upimgbtnshow = "bannerimghide" : this.upimgbtnshow = ""
    },
    bannerimgerror() {
      this.$message.error('上传失败，请稍后再试！');
    },
    removebannerimg(file) {
      this.newrightbannersave.forEach((v, i) => {
        if (v.url == file.url) {
          this.newrightbanner.splice(i, 1)
          this.newrightbannersave.splice(i, 1)
        }
      })
      this.newrightbannersave.length == 3 ? this.upimgbtnshow = "bannerimghide" : this.upimgbtnshow = ""
    },





    inputimglink1() {
      this.newrightbanner[0] = this.adimginput1
    },
    inputimglink2() {
      this.newrightbanner[1] = this.adimginput2
    },
    inputimglink3() {
      this.newrightbanner[2] = this.adimginput3
    },
    ohhhhh() {
      this.testtableData = this.ahhhhhhhhhh
    },
    previewbtnclick() {
      this.previewwindowshow = true
    },
    onoffline() {
      //
    },
    /* 编辑客户互动菜单 */
    usermenuedit(index, val) {
      this.dialogVisible = true
      this.tctitle = "修改菜单"
      this.redactId = index
      this.ruleForm.resource = val.resource
      if (val.resource == 2) {
        this.ruleForm.name = val.date
        this.ruleForm.domains = val.domains
        this.ruleForm.email = val.email
        if (val.email) {
          this.erji = true
        }
      } else {
        this.ruleForm.name = val.date
        this.ruleForm.desc = val.desc
      }
    },
    /* 编辑岗位互动菜单 */
    user1menuedit(index, val) {
      this.dialog1Visible = true
      this.tctitle = "修改菜单"
      this.redactId = index
      this.rule1Form.resource = val.resource
      if (val.resource == 2) {
        this.rule1Form.name = val.date
        this.rule1Form.domains = val.domains
        this.rule1Form.email = val.email
        if (val.email) {
          this.erji = true
        }
      } else {
        this.rule1Form.name = val.date
        this.rule1Form.desc = val.desc
      }
    },
    /* 删除客户互动菜单 */
    usermenudel(index) {
      this.tableData.splice(index, 1);
    },
    // 添加快速咨询菜单
    pushconsultationmenu() {
      this.consultation.push({
        title: "",
        content: "",
      })
    },
    popconsultationmenu() {
      this.consultation.pop()
    },
    /* 删除岗位互动菜单 */
    user1menudel(index) {
      this.table1Data.splice(index, 1);
    },
    themecolorchange(val) {
      this.themecolor = val
    },
    kfbubblechange(val) {
      this.kfbubble = val
    },
    kfmeschange(val) {
      this.kfmes = val
    },
    fkbubblechange(val) {
      this.fkbubble = val
    },
    fkmeschange(val) {
      this.fkmes = val
    },
    sendbtnchange(val) {
      this.sendbtn = val
    },
    nwsclick() {
      // console.log(this.new_window_size)
    },
    fbpclick() {
      this.btnpxbnum = this.btnpxrnum = 0
      this.float_button_position == "btnp1" ? this.btnpunit = "px" : this.btnpunit = "%"
    },
    fbsclick() {
      // console.log(this.float_button_style)
    },
    fwsclick() {
      // console.log(this.float_window_size)
    },
    fifclick(val) {
      // console.log(val)
    },
    handleClick(tab, event) {
      // console.log(tab.label);
    },
    async savebtn() {
      if (this.stylecontentshow == 0) {
        alert("请选择修改的站点")
        return false
      }
      let savedatas = {
        chatbtn: {
          btnstyle: this.float_button_style,          //按钮样式
        },
        btnposition: {
          pixelorpercentage: this.float_button_position,    //像素还是百分比
          leftrightmargin: this.btnpx,                //左边距还是右边距
          topbottommargin: this.btnpy,                //上边距还是下边距
          onemargin: this.btnpxrnum,                  //第一个边距值
          twomargin: this.btnpxbnum                   //第二个边距值
        },
        themetxt: {
          maincolor: this.themecolor,                 //主题颜色
          onlinetxt: this.onlinetxt,                  //在线按钮文本
          offlinetxt: this.offlinetxt,                //离线按钮文本
        },
        chatsetting: {
          kfbubble: this.kfbubble,                    //客服气泡颜色
          kfmes: this.kfmes,                          //客服消息颜色
          fkbubble: this.fkbubble,                    //访客气泡颜色
          fkmes: this.fkmes,                          //访客消息颜色
          sendbtn: this.sendbtn                       //发送按钮颜色
        },
        newwindos: {
          kfsign: this.sign,                           //客服签名
          rightbannerimg: this.newrightbanner,          //右侧广告图片
          rightadtxt: this.endeditorText,                    //右侧底部文字
        },
        menu: {
          // automatic: this.xiaox,
          Text: this.tableData
        },
        station: {
          TexT: this.table1Data
        },
        consultationmenu: {
          fastconsult: this.consultation
        }
      }
      let endsavedatas = {
        site_id: this.optionsId,
        type: "0",
        setting: JSON.stringify(savedatas)
      }
      await stylesave(endsavedatas).then(res => {
        if (res.data.code == 200) {
          this.$message.success("保存成功！")
        } else {
          this.$message.error("保存失败，请稍后再试！")
        }
      }).catch(() => {
        this.$message.error("保存失败，请稍后再试！")
      })
    },
  },
};
</script>

<style scoped>
.maxpc {
  height: calc(100vh - 70px);
}

.pcstyle ::v-deep .el-tabs__nav {
  margin-left: 16px;
}

.pcstyle ::v-deep .el-tabs__header {
  margin: 0;
}

.pcstyle img {
  user-select: none;
}

.float-window,
.new-window,
.kefu-icon {
  display: flex;
}

.float-window-left {
  width: 417px;
  height: 773px;
  padding: 15px 30px 0 16px;
  box-sizing: content-box;
  overflow: auto;
  overflow-x: hidden;
}

.float-window-list {
  display: flex;
  align-content: flex-start;
  position: relative;
  margin-bottom: 20px;
}

.pcstyle .fwl-tle {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e1e1e6;
  line-height: 40px;
  font-size: 18px;
  color: #333;
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.float-window-label {
  color: #333;
  font-weight: initial;
  line-height: 20px;
  position: absolute;
  left: 0;
}

.float-window-list ::v-deep * {
  font-size: 12px;
}

.float-window-list > div {
  margin-left: 146px;
}

.float-window-list ::v-deep label:focus {
  box-shadow: initial !important;
}

.float-window-list ::v-deep label span {
  user-select: none;
}

.float-button-style ::v-deep label {
  margin-right: 15px;
}

.float-button-style ::v-deep label span {
  position: relative;
  width: 50px;
  height: 50px;
  float: left;
  border: 2px solid #CED5E0 !important;
  cursor: pointer;
  border-radius: 0 !important;
  padding: 0 !important;
  transition: initials;
  color: #ffffff00;
}

.float-button-style ::v-deep label span::after {
  content: "";
  position: absolute;
  background: #CED5E0;
}

.float-button-style ::v-deep label:nth-child(1) span::after {
  width: 22px;
  height: 7px;
  bottom: 0;
  right: 2px
}

.float-button-style ::v-deep label:nth-child(2) span::after {
  width: 7px;
  height: 22px;
  top: 20px;
  right: 0;
}

.float-button-style ::v-deep label:nth-child(3) span::after {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  bottom: 5px;
  right: 5px;
}

.float-button-style ::v-deep .is-active span {
  border-color: #3B99FC !important;
  background-color: initial;
  box-shadow: initial;
}

.float-button-style ::v-deep .is-active span::after {
  background-color: #3B99FC;
}

.float-button-position ::v-deep label:first-child {
  margin-right: 20px;
}

.float-button-position ::v-deep label .el-radio__label {
  line-height: 20px;
  padding-left: 5px;
}

.float-button-position ::v-deep label .el-radio__inner {
  width: 14px;
  height: 14px;
}

.float-window-list ::v-deep .btnpxrbox {
  width: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.float-window-list ::v-deep .btnpxrbox input {
  padding-left: 6px;
}

.float-window-list ::v-deep .btnpxrbox i {
  width: 15px;
}

.float-window-list ::v-deep .btnpxnum span {
  width: 16px;
}

.float-window-list ::v-deep .el-input--mini .el-input__inner {
  border-radius: 2px;
}

.float-window-list ::v-deep .btnpxnum {
  width: 60px;
}

.float-window-list ::v-deep .btnpxnum input {
  padding: 0;
  text-align: right;
  padding-right: 36px;
}

.float-window-list ::v-deep .btnpixbox,
.float-window-list ::v-deep .btnpctbox {
  width: 200px;
  margin-top: 10px;
}

.float-window-list ::v-deep .btnpixbox .el-input,
.float-window-list ::v-deep .btnpctbox .el-input {
  position: relative;
}

.float-window-list ::v-deep .btnpixbox .btnpxnum .el-input::before,
.float-window-list ::v-deep .btnpctbox .btnpxnum .el-input::before {
  content: "px";
  position: absolute;
  color: #606266;
  left: 24px;
  top: 0;
}

.float-window-list ::v-deep .btnpixbox .btnpxnum .el-input::before {
  content: "%";
}

.float-window-list ::v-deep .btnpixbox label {
  font-weight: initial;
  margin-right: 5px;
}

.float-window-list ::v-deep .btnpixbox label:nth-of-type(2) {
  margin-left: 15px;
}

.float-window-list ::v-deep .themecolor {
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;
  width: 110px;
  height: 28px;
  border: 1px solid #D3D9E0;
  line-height: 26px;
  font-size: 12px;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  padding: 3px;
}

.float-window-list ::v-deep .themecolor .el-color-picker {
  height: 20px;
  margin-right: 5px;
}

.float-window-list ::v-deep .themecolor .el-color-picker__trigger {
  padding: 0;
  border: 0;
  width: 20px;
  height: 20px;
}

.float-window-list ::v-deep .themecolor .el-color-picker__trigger .el-color-picker__icon,
.float-window-list ::v-deep .themecolor .el-color-picker__trigger .el-color-picker__empty {
  display: none;
}

.float-window-list ::v-deep .themecolor .el-color-picker__color {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.linetxt {
  width: 160px;
  margin-left: 5px;
  position: relative;
}

.linetxt ::v-deep input {
  padding: 0 5px;
}

.linetxttip {
  color: #8DA2B5;
  line-height: 20px;
  margin: 5px 0 10px 52px;
}

.windowsize {
  display: flex;
  flex-wrap: wrap;
}

.float-input-func {
  width: 320px;
}

.float-input-func ::v-deep label {
  margin-bottom: 6px;
}

.float-input-func ::v-deep .el-checkbox {
  margin-right: 25px;
}

.float-input-func ::v-deep .el-checkbox__label {
  padding-left: 5px;
}

.newaddmenu {
  padding: 7px 10px;
}

.usermenupreview {
  margin-top: -10px;
}

.menupreview ::v-deep * {
  font-size: 12px;
  color: #62778C;
}

.menupreview span:last-child {
  color: #C72892;
  margin-left: 23px;
  position: relative;
  cursor: pointer;
}

.menupreview span:last-child img {
  display: none;
  width: 310px;
  border-radius: 5px;
  border: 5px solid #00000000;
  background-color: #28334b;
  position: absolute;
  left: 50%;
  bottom: 32px;
  margin-left: -155px;
  z-index: 1;
}

.menupreview span:last-child:hover img {
  display: block;
}

.menupreview ::v-deep .el-checkbox__input {
  transform: scale(.8);
}

.menupreview ::v-deep .el-checkbox__label {
  padding-left: 5px;
}

.usermenuautohide {
  margin-top: 20px;
}

.usermenuautohide span:first-child {
  display: inline-block;
  width: 142px;
}

.float-window-left .usermenucontent {
  margin-left: 0;
  margin-top: 10px;
}

.float-window-left ::v-deep .usermenucontent .el-table__header-wrapper {
  border: 1px solid #d8dfea;
}

.float-window-left ::v-deep .usermenucontent .el-table__header-wrapper th {
  background-color: #f7f8fa;
  border-bottom: 0;
}

.float-window-left ::v-deep .usermenucontent .el-table__header-wrapper th,
.float-window-left ::v-deep .usermenucontent .el-table__body-wrapper td {
  padding-top: 0;
  padding-bottom: 0;
  height: 35px;
}

.float-window-left ::v-deep .usermenucontent .el-table__header-wrapper th:nth-last-child(2),
.float-window-left ::v-deep .usermenucontent .el-table__body-wrapper td:last-child {
  text-align: center;
}

.float-window-left ::v-deep .usermenucontent .el-table__body-wrapper td:first-child div {
  padding-right: 0;
}

.endsave {
  margin: 60px 0 30px;
}

.endsave button {
  height: 30px;
  padding: 0 10px;
  font-size: 14px;
}


.endsave .el-button + .el-button {
  margin-left: 20px;
}



.mobileitem:hover {
  color: #46a6ff;
}

.previewbox {
  padding-top: 15px;
  margin-left: 16px;
}

.float-previewbox .fwl-tle ::v-deep .is-active .el-radio-button__inner {
  background: #FFFFFF;
  border-color: #1890ff;
  color: #1890ff;
}

.float-previewbox .fwl-tle ::v-deep .el-radio-button:focus {
  box-shadow: initial;
}

.float-previewbox .fwl-tle ::v-deep .el-radio-button .el-radio-button__inner {
  border-radius: 2px 0 0 2px;
  padding: 7px 10px;
  transition: none;
}

.float-previewbox .fwl-tle ::v-deep .el-radio-button + .el-radio-button .el-radio-button__inner {
  border-radius: 0 2px 2px 0;
}

.previewcontent {
  width: 360px;
  height: 460px;
  position: relative;
}

.previewcontent > div {
  width: 100%;
  height: 100%;
}

.previewcontent .onlinestyle {
  background-color: #62778C;
}

.previewcontent .offlinestyle {
  background-color: #333;
}

.previewbtnboxs {
  position: relative;
}

.previewbtnone {
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  position: absolute;
}

.previewbtnone span {
  font-size: 16px;
  color: #fff;
  line-height: 42px;
  padding-left: 8px;
  vertical-align: middle;
  user-select: none;
}

.previewbtnone svg {
  box-sizing: border-box;
}

.previewbtnone svg:first-child,
.previewbtnone svg:nth-of-type(2),
.previewbtnone svg:last-child {
  color: #fff;
  width: 42px;
  height: 42px;
  padding: 9px;
  vertical-align: middle;
  background-color: #00000020;
}

.previewbtnone svg:last-child {
  background-color: initial;
}

.previewbtnone i {
  display: block;
  width: 15px;
  height: 15px;
  color: #fff;
  font-style: initial;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 5px;
  left: 21px;
}

.previewbtnthree i {
  left: initial;
  right: 11px;
  top: 11px;
}

.previewbtntwo {
  width: 42px;
  height: initial;
}

.previewbtntwo span {
  display: block;
  box-sizing: border-box;
  padding: 8px 12px 16px;
  line-height: 20px;
  text-align: center;
  word-wrap: break-word;
}

.previewbtnthree {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 9%), 0 2px 32px 0 rgb(0 0 0 / 15%);
}

.previewbtnboxs .previewbtnthree svg {
  display: block;
  width: 100%;
  height: 100%;
  padding: 14px;
}

.previewwindow {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 5px 40px 0 #d8d8d8;
}

.prewindowhead {
  height: 36px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prewindowhead svg {
  width: 36px;
  height: 36px;
  color: #fff;
  padding: 6px;
  box-sizing: border-box;
}

.prewindowheadright {
  height: 100%;
}

.prewindowhead svg:hover {
  background-color: #3698ed;
}

.prewindowhead svg:last-child {
  transform: rotate(180deg);
}

.kefuphoto {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kefuphoto img {
  width: 24px;
}

.chatcontent {
  overflow: auto;
  height: 330px;
  box-sizing: border-box;
  background-color: #f8f7f7;
  border-bottom: 1px solid #ededed;
  text-align: center;
  padding: 8px 15px 30px;
}

.chatcontent p {
  margin: 0;
}

.chatcontent .chattip {
  margin-bottom: 16px;
}

.chattip span {
  font-size: 12px;
  padding: 5px 10px;
  color: #62778C;
  background-color: #eff3f6;
  margin-bottom: 16px;
}

.kefumes {
  display: flex;
  font-size: 12px;
  margin-bottom: 15px;
}

.kefumesctt {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-left: 6px;
}

.kefumesctt p {
  text-align: left;
  margin-bottom: 5px;
  color: #6e7a89;
}

.kefumesctt p span {
  margin-right: 5px;
}

.kefumesctt > span {
  padding: 8px;
  font-size: 14px;
  max-width: 270px;
  letter-spacing: 0.63px;
  text-align: left;
  line-height: 20px;
  border-radius: 4px;
}

.usermes {
  justify-content: flex-end;
}

.usermes .kefumesctt {
  position: initial;
}

.usermenubox {
  width: 100%;
  height: 33px;
  background-color: #f8f7f7;
  position: absolute;
  bottom: 95px;
  overflow: hidden;
}

.usermenuson {
  display: flex;
}

.usermenuitem {
  user-select: none;
  background: #FFF;
  font-size: 12px;
  height: 26px;
  color: #62778C;
  padding: 4px 8px;
  border-radius: 15px;
  border: 1px solid #CED5E0;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin: 0 4px;
  line-height: 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.usermenuswiper {
  margin-top: 3px;
}

.usermescontent {
  height: 58px;
  width: 100%;
}

.usermescontent ::v-deep .el-textarea__inner {
  color: #000;
  letter-spacing: 0.6px;
  padding: 5px;
  outline: 0;
  height: 100% !important;
  resize: none;
  border: 0;
  box-sizing: border-box;
  font-size: 12px;
  font-family: monospace;
  line-height: 1.5;
}

.sendfunc {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.sendfunc a {
  color: #8FA1B3;
  margin-right: 5px;
}

.sendfunc a:hover {
  color: #1f8ceb;
}

.sendmesbtn {
  position: absolute;
  right: 15px;
  bottom: 8px;
  border: 0;
  font-size: 14px;
}

.offlinecontent {
  flex: 1;
  padding: 10px 20px 10px 20px;
  background-image: linear-gradient(-180deg, #FFFDFB 12%, #F8F7F7 90%);
}

.offlinecontent p {
  font-size: 14px;
  color: #3A3C4C;
  letter-spacing: 0.63px;
  line-height: 22px;
  margin: 0;
}

.offlinecontent label {
  display: block;
  font-size: 12px;
  color: #555c6f;
  margin-bottom: 5px;
  margin-top: 10px;
  position: relative;
  font-weight: initial;
}

.offlinecontent label span {
  color: red;
}

.offlinecontent ::v-deep .el-input__inner {
  border-radius: 2px;
  border: 1px solid #d3d9e0;
  padding: 0 6px;
  color: #28334B;
  font-size: 14px;
}

.offlinesendmesbtn {
  width: 100%;
  margin: 20px 0;
  border: 0;
  height: 30px;
}

.graytxt {
  color: #8DA2B5;
}

.sign ::v-deep input {
  width: 280px;
  padding: 0 6px;
  color: #28334B;
}

.aboutedit ::v-deep textarea {
  width: 240px !important;
  padding: 6px;
}

/* .aboutedit ::v-deep .ke-container {
  width: 240px !important;
}

.aboutedit ::v-deep .ke-statusbar {
  display: none;
} */

.new-window-threetip {
  display: block;
  color: #8DA2B5;
  font-size: 12px;
  margin-top: -3px;
}

.adimginput {
  width: 360px;
  margin-top: 20px;
}

.adimginput ::v-deep input {
  padding: 0 6px;
  border-radius: 2px;
}

.new-window .float-window-left {
  width: 500px;
}

.newpreviewcontent {
  width: 560px;
  height: 460px;
  display: flex;
  flex-direction: column;
  border: 1px solid #D3D9E0;
  box-shadow: 0 3px 20px rgb(0 0 0 / 15%);
}

.newpreviewcontent .newpreviewhead {
  width: 100%;
  height: 42px;
  padding: 7px 11px;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
}

.newpreviewhead img {
  width: 28px;
  height: 28px;
  background-color: #fff;
}

.newpreviewhead span {
  font-size: 12px;
  color: #fff;
  transform: scale(.9);
}

.newpreviewchat {
  display: flex;
}

.newpreviewchatleft {
  flex: 1;
  position: relative;
  border-right: 1px solid #E6E6E6;
}

.newpreviewchatright {
  width: 168px;
}

.newpreviewchatctt {
  height: 290px;
  overflow: hidden;
}

.newpreviewchatctt .chatcontent {
  position: absolute;
  left: -49px;
  top: -33px;
  background-color: #fff;
  -webkit-transform: scale(.8);
  transform: scale(.8);
  width: 487px;
  height: 359px;
  border: 0;
}

.newpreviewsendfunc {
  background-color: #f8fafc;
  border-top: 1px solid #DDDFE5;
  border-bottom: 1px solid #DDDFE5;
  position: relative;
  height: 28px;
  box-sizing: border-box;
}

.newpreviewsendfunc .sendfunc {
  position: absolute;
  transform: scale(.8);
  left: -12px;
  top: 1px;
}

.newsendmesbtn .sendmesbtn {
  transform: scale(.8);
}

.npcrtle {
  display: flex;
  align-items: center;
  height: 34px;
  border-bottom: 1px solid #E6E6E6;
}

.npcrtle span {
  flex: 1;
  font-size: 12px;
  display: inline-block;
  text-align: center;
}


.npcrcontent {
  width: 100%;
  /* height: 234px; */
  font-size: 13px;
  padding: 10px;
  line-height: 18px;
  box-sizing: border-box;
}

.newrightbanner {
  height: 270px;
  margin: 10px;
}

.newrightbanner img {
  width: 100%;
  height: 100%;
}



.bannerimghide ::v-deep .el-upload--picture-card {
  display: none !important;
}

.upimgbox {
  margin-top: 20px;
}

.upimgbox ::v-deep .el-upload--picture-card,
.upimgbox ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 150px;
  height: 300px;
}

.upimgbox ::v-deep .el-upload--picture-card,
.upimgbox ::v-deep .el-upload-list__item {
  transition: none !important
}

.upimgbox ::v-deep .el-upload--picture-card {
  margin-bottom: 16px;
}

.upimgbox ::v-deep .el-upload--picture-card i {
  line-height: 11;
}

.bannerimgtxt {
  margin-top: 6px;
}

.bannerimgtxt ::v-deep textarea {
  /* width: 300px; */
  padding: 6px;
}

.demonstration {
  font-size: 16px;
  padding-left: 15px;
}

.block {
  margin-top: 10px;
}

.consultationmenu {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.consultationmenusub {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.consultationmenusub div:first-child {
  width: 120px;
}

.consultationmenusub div:last-child {
  width: 280px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  left: -1px;
}

.consultationmenusub div:first-child ::v-deep .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.consultationmenusub div:last-child ::v-deep .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.consultationmenusub div ::v-deep .el-input__inner:focus {
  position: relative;
}

.consultationmenusub div ::v-deep .el-input__inner:focus {
  z-index: 99;
}

.consultationmenubtn {
  width: 190px;
  display: flex;
  margin-left: 0 !important;
}

.consultationmenu .el-button {
  width: 90px;
  height: 40px;
  position: relative;
  top: 1px;
  border-radius: 4px;
}

.consultationmenu .el-button .el-icon-plus {
  font-weight: bold;
}
</style>